import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/produkty/sudafed_xylospray_dla_dzieci/" className="sel">
              {" "}
              &gt; SUDAFED® XYLOSPRAY DLA DZIECI
            </a>
          </div>
          <div id="page_content">
            <div className="col2">
              <div className="product_content">
                <div className="product_name">
                  <div className="info">
                    <p className="name">
                      SUDAFED<sup>®</sup> <br />
                      XYLOSPRAY DLA DZIECI
                    </p>
                    <div className="desc" />
                  </div>
                  <div className="photo">
                    <span className="stamp h10">
                      <img
                        src="/upload/znaczek_AZ3_v2.png"
                        alt="AZ3"
                        id="az3"
                      />
                      <img src="/upload/10h_znaczek.png" alt="10h" id="h10" />
                      <img
                        src="/upload/krople_znaczek.png"
                        alt="Zawiera kwas hialuronowy"
                        id="kh"
                      />
                      <img
                        src="/upload/zielony.png"
                        alt="Nie zawiera konserwantó"
                        id="nzk"
                      />
                    </span>
                    <img src="/web/kcfinder/uploads/files/produkty/dla_dzieci.png" />
                  </div>
                </div>
                <div className="wysiwyg">
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    Nosek dziecka jest inny niż dorosłego i wymaga wyjątkowego
                    podejścia oraz delikatności. Sudafed<sup>®</sup> XyloSpray
                    dla dzieci szybko odblokowuje nos i zatoki. Działa aż do 10
                    godzin<sup>1</sup> – podany przed snem umożliwia spokojną,
                    noc bez problemów z oddychaniem. Rozpyla delikatną mgiełkę i
                    nie powoduje dyskomfortu podczas aplikacji. Dodatkowo
                    zawiera{" "}
                    <a href="/slowniczek/#sorbitol" target="_blank">
                      sorbitol,
                    </a>
                    &nbsp;substancję o działaniu nawilżającym.
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    &nbsp;
                  </div>
                  <ul>
                    <li
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      szybko i delikatnie odblokowuje nos i zatoki
                    </li>
                    <li
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      delikatna mgiełka podczas aplikacji
                    </li>
                    <li
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      działa do 10 godzin
                    </li>
                    <li
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      od 2. roku życia
                    </li>
                    <li
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      zawiera sorbitol – substancję o działaniu nawilżającym
                      &nbsp;
                    </li>
                  </ul>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    &nbsp;
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    &nbsp;
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    Wskazania i dawkowanie:
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    &nbsp;
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    Preparat należy stosować w krótkotrwałym leczeniu
                    niedrożności nosa i zatok występujących w przebiegu{" "}
                    <a href="/slowniczek/#przeziebienie" target="_blank">
                      przeziębienia
                    </a>{" "}
                    lub{" "}
                    <a
                      href="/slowniczek/#zapalenia_blony_sluzowej_nosa"
                      target="_blank"
                    >
                      zapalenia zatok.
                    </a>
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    &nbsp;
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    Przeznaczony jest do stosowania u dzieci powyżej 2 roku
                    życia. Jeżeli lekarz nie zaleci innego sposobu dawkowania,
                    lek można aplikować dziecku trzy razy na dobę, po jednej
                    dawce do każdego otworu nosowego, przez maksymalnie siedem
                    dni.&nbsp;
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    &nbsp;
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    Sudafed<sup>®</sup> XyloSpray dla dzieci można używać do 20
                    tygodni po otwarciu butelki.
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    &nbsp;
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    <span
                      style={{
                        "font-size": "11px"
                      }}
                    >
                      UWAGA!
                    </span>
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    <span
                      style={{
                        "font-size": "11px"
                      }}
                    >
                      Aby zminimalizować ryzyko rozprzestrzeniania się zakażeń,
                      opakowanie leku nie powinno być stosowane przez więcej niż
                      jedną osobę, a aplikator należy opłukać gorącą wodą po
                      każdym użyciu.
                    </span>
                  </div>
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    <br />
                    <span
                      style={{
                        "font-size": "11px"
                      }}
                    >
                      <span
                        style={{
                          "font-size": "10px"
                        }}
                      >
                        <sup>1</sup>dotyczy efektu zmniejszenia obrzęku błony
                        śluzowej nosa
                      </span>
                    </span>
                  </div>
                  <div>&nbsp;</div>
                </div>
                <a
                  href="/web/kcfinder/uploads/files/pdf/Sudafed XyloSpray dla dzieci_SmPC_PL_04.2021.pdf"
                  className="pdf btn_link"
                  target="_blank"
                >
                  Przeczytaj ulotkę
                </a>
              </div>
            </div>
            <div className="col1">
              <div className="related">
                <p className="name">Dowiedz się więcej</p>
                <div className="line">
                  <a href="/poradnik/dzieci/zatkany_nos_u_dziecka/">
                    <div className="c1">
                      <span className="photo" style={{"background-image":"url('/web/kcfinder/uploads/files/pokonaj_zatkany_nos-_dziecka_szybko_skutecznie.png')"}} />
                    </div>
                    <div className="c2">Zatkany nos u dziecka </div>
                  </a>
                </div>
                <div className="line">
                  <a href="/poradnik/dzieci/odpornosc_dziecka_-_zadbaj_i_zwiekszaj/">
                    <div className="c1">
                      <span className="photo" style={{"background-image":"url('/web/kcfinder/uploads/files/iStock_63844397_LARGE.png')"}} />
                    </div>
                    <div className="c2">
                      Odporność dziecka - zadbaj i zwiększaj{" "}
                    </div>
                  </a>
                </div>
              </div>
              <a href="/dobierz-lek/" className="link_box link">
                <img src="/web/kcfinder/uploads/files/box/zrobtest.png" />
              </a>
              <a href="/reklama-telewizyjna/" className="link_box video">
                <img src="/web/kcfinder/uploads/files/box/SudafedHAthumbnail.png" />
              </a>
              <a
                href="/produkty/sudafed_xylospray_dla_dzieci/"
                className="quiz_btn"
              />
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px"
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a id="ot-sdk-btn" className="ot-sdk-show-settings" rel="nofollow"> Ustawienia plików Cookie </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty. 2023
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
